
<template>
  <SearchClientsList />
</template>

<script>
  export default {
    components: {
      SearchClientsList: () => import('@/components/people/search-clients/SearchClientsList')
    }
  }
</script>